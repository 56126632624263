<template>
  <!-- Version: 1.7 -->
  <h2>1. Descripción del juego</h2>
  <p>
    El juego Soccerbet de Highlight Games es un software único que simula los momentos más destacados de los partidos de
    fútbol seleccionados aleatoriamente para crear oportunidades de juego.
  </p>
  <h2>2. Descripción y duración del juego</h2>
  <p>
    Una sola ronda de una quiniela electrónica dura entre 3 y 5 minutos. Consiste en el anuncio del encuentro, el
    preámbulo, la visualización durante el juego y el resultado. La presentación del juego dura 1 minuto.
  </p>
  <p>
    La selección de eventos futbolísticos destacados es estadísticamente independiente, aleatoria e impredecible, y
    generada por el Generador de Números Aleatorios - RNG.
  </p>
  <h2>3. Mercados de apuestas</h2>
  <h3>3.1 Goles exactos</h3>
  <p>
    La apuesta Número Exacto de Goles significa que se debe marcar un número exacto de goles durante un partido
    individual. Como tal, esta variante de apuesta significa que el apostante debe predecir el número exacto de goles
    marcados independientemente del resultado final del partido.
  </p>
  <h3>3.2 Goles exactos (equipo local)</h3>
  <p>Apuesta sobre el número exacto de goles que marcará el equipo local durante el partido.</p>
  <h3>3.3 Goles exactos (equipo visitante)</h3>
  <p>Apuesta sobre el número exacto de goles que marcará el equipo visitante durante el partido.</p>
  <h3>3.4 Ambos equipos marcarán</h3>
  <p>
    Ambos equipos deben marcar al menos un gol, por lo que cualquier resultado a partir de 1-1 es una apuesta ganadora.
    La apuesta &quot;Ambos equipos marcarán&quot; ofrece a los apostantes la posibilidad de aprovechar este tipo de
    eventos para obtener cuotas rentables cuando sólo uno de los dos equipos ha conseguido marcar.
  </p>
  <h3>3.5 Resultado correcto</h3>
  <p>
    El mercado &quot;Marcador correcto&quot; requiere que el apostante seleccione correctamente el resultado final de un
    partido de fútbol. Con un máximo de 6 goles posibles durante un partido, hay 28 resultados posibles.
  </p>
  <h3>3.6 Doble oportunidad</h3>
  <p>
    Una apuesta &quot;Doble oportunidad&quot; permite al apostante cubrir dos de los tres posibles resultados de un
    partido de fútbol con una sola apuesta. Equipo local y empate - La apuesta es ganadora si el equipo local gana o
    empata el partido. Equipo visitante y empate - La apuesta es ganadora si el equipo visitante gana o empata el
    partido.
  </p>
  <h3>3.7 Resultado del partido (1X2)</h3>
  <p>Apuesta a que el equipo local gana (1), el equipo visitante gana (2) o el partido termina en empate (X).</p>
  <h3>3.8 Resultado del partido e Ambos equipos marcarán (1X2 + AEM)</h3>
  <p>
    Una apuesta sobre el resultado del partido (1 es victoria local, X es empate, 2 es victoria visitante) y si ambos
    equipos marcan o no.
  </p>
  <ul>
    <li>1 + Gol - El equipo local gana y ambos equipos marcan al menos un gol [por ejemplo, 3-1].</li>
    <li>1 + Sin gol - El equipo local gana y es el único equipo que marca un gol [por ejemplo, 1-0].</li>
    <li>X + Gol - Apuesta por un empate y ambos equipos marcan [por ejemplo, 1-1, 2-2].</li>
    <li>X + Sin gol - Apueste a un empate y ningún equipo marca [sólo 0-0].</li>
    <li>2 + Gol - El equipo visitante gana y ambos equipos marcan al menos un gol [por ejemplo, 1-2, 1-3].</li>
    <li>2 + Sin gol - El equipo visitante gana y es el único equipo que marca goles [por ejemplo, 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Resultado del partido y Más/Menos de 1,5 goles</h3>
  <p>Una apuesta al resultado del partido de 1X2 y al número total de goles que sea inferior o superior a 1,5 goles.</p>
  <h3>3.10 Resultado del partido y Más/Menos de 2,5 goles</h3>
  <p>Una apuesta al resultado del partido de 1X2 y al número total de goles que sea inferior o superior a 2,5 goles.</p>
  <h3>3.11 Total de goles (1 a 2, 1 a 3, 1 a 4, 2 a 3, 2 a 4)</h3>
  <p>
    Una apuesta a que el partido tendrá 1 a 2, 1 a 3, 1 a 4, 2 a 3 o 2 a 4 goles en total como resultado final. Se trata
    de una apuesta SÍ o NO.
  </p>
  <h3>3.12 Más de/Menos de 1,5 goles (equipo local)</h3>
  <p>Apuesta a que el equipo local marcará más o menos de 1,5 goles en el partido.</p>
  <h3>3.13 Más de/Menos de 1,5 goles (equipo visitante)</h3>
  <p>Apuesta a que el equipo visitante marcará más o menos de 1,5 goles en el partido.</p>
  <h3>3.14 Más de/Menos de 1,5/2,5/3,5/4,5 goles en total</h3>
  <p>
    Apuesta a que el número total de goles marcados por ambos equipos será inferior o superior a 1,5, 2,5, 3,5 ó 4,5 en
    el partido.
  </p>
</template>
